<template>
    <div class="pagepiling">
        <div class="menu">
            <span class="close-menu fa fa-close right-boxed" @click="clicked"></span>
            <!--            <div class="menu-lang right-boxed">-->
            <!--                <a href="#" class="active">Eng</a>-->
            <!--                <a href="#">Fra</a>-->
            <!--                <a href="#">Ger</a>-->
            <!--            </div>-->
            <ul class="menu-list right-boxed">
                <li @click="clicked" data-menuanchor="page1">
                    <a href="#home">Home</a>
                </li>
                <li @click="clicked" data-menuanchor="page2">
                    <a href="#aboutMe">About Me</a>
                </li>
                <li @click="clicked" data-menuanchor="page4">
                    <a href="#associatedBrands">Associated Brands</a>
                </li>
                <li @click="clicked" data-menuanchor="page3">
                    <a href="#media">Media Coverage</a>
                </li>
                <li @click="clicked" data-menuanchor="page5">
                    <a href="#contact">Contact Me</a>
                </li>
            </ul>
            <div class="menu-footer right-boxed">
                <div class="sidenav-article">
                    <div class="h5 mt-0 mb-3 small">CONTACT INFO</div>
                    <div class="empty-space col-xs-b10"></div>
                    <p class="text-primary mb-3">
                        Email:
                        <a target="_blank" href="mailto:sanishbhaskaran@gmail.com" class="mouseover-simple"
                           style="color: rgb(255, 255, 255);">
                            <b>sanishbhaskaran@gmail.com</b>
                        </a>
                    </p>
                    <p class="text-primary mb-3 d-none">
                        Phone:
                        <a href="tel:+0009872251" class="mouseover-simple" style="color: rgb(255, 255, 255);">
                            <b>+000 987 22 51</b>
                        </a>
                    </p>
                </div>
                <div class="social-list">
                    <a target="_blank" href="https://twitter.com/sanishbhaskaran" class="fa fa-twitter"></a>
                    <a target="_blank" href="https://www.instagram.com/sanish.bhaskaran/" class="fa fa-instagram"></a>
                    <a target="_blank" href="https://www.linkedin.com/in/sanish-bhaskaran-a6486718/"
                       class="fa fa-linkedin"></a>
                    <a target="_blank" href="https://surestory.in/" class="fa fa-dribbble"></a>
                </div>
                <div class="copy">© Sanish Bhaskaran {{ new Date().getFullYear() }}. All Rights Reseverd<br> Design by
                    <a class="text-white" target="_blank" href="https://xeoscript.com/">Xeoscript Technologies</a></div>
            </div>
        </div>
        <div id="overlay" @click="clicked"></div>

        <header class="navbar navbar-white navbar-2 boxed" id="header" v-bind:class="navClass()">
            <div class="navbar-bg"></div>

            <a class="brand" href="#">
                <img class="brand-img" alt="" src="../assets/images/LOGO.png">
                <!--                <div class="brand-info">-->
                <!--                    <div class="brand-name">Jonny</div>-->
                <!--                    <div class="brand-text">creative template</div>-->
                <!--                </div>-->
            </a>
            <div class="social-list hidden-xs mr-auto">
                <a target="_blank" href="https://twitter.com/sanishbhaskaran" class="fa fa-twitter"></a>
                <a target="_blank" href="https://www.instagram.com/sanish.bhaskaran/" class="fa fa-instagram"></a>
                <a target="_blank" href="https://www.linkedin.com/in/sanish-bhaskaran-a6486718/"
                   class="fa fa-linkedin"></a>
                <a target="_blank" href="https://surestory.in/" class="fa fa-dribbble"></a>
            </div>

            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse"
                    aria-expanded="false" @click="sideNav">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </header>

        <div class="copy-bottom white boxed">© Sanish Bhaskaran {{ new Date().getFullYear() }}.</div>
        <!--        <div class="lang-bottom white boxed">-->
        <!--            <div class="menu-lang">-->
        <!--                <a href="#" class="active">Eng</a>-->
        <!--                <a href="#">Fra</a>-->
        <!--                <a href="#">Ger</a>-->
        <!--            </div>-->
        <!--        </div>-->

        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>

    </div>
</template>

<script>
export default {
    name: 'BaseLayout',
    metaInfo: {
        titleTemplate: 'Voice Artist| Script Writer| Reputation Management Expert',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            { name: 'description', content: 'Official website of Sanish Bhaskaran.' },
            { name: 'keywords', content: 'sanish bhaskaran, voice artist, script writer, Reputation management expert' }
        ]

    },
    data () {
        return {
            scrollPosition: null
        };
    },
    methods: {

        updateScroll () {
            this.scrollPosition = window.scrollY;
        },

        sideNav () {
            const element2 = document.getElementById('overlay');
            if (element2) {
                element2.classList.add('active');
            }
            document.body.classList.add('menu-is-opened');
        },
        clicked () {
            const element2 = document.getElementById('overlay');
            if (element2) {
                element2.classList.remove('active');
            }
            document.body.classList.remove('menu-is-opened');
        },
        navClass () {
            const element3 = document.getElementById('header');
            if (this.scrollPosition > 50) {
                if (element3) {
                    element3.classList.remove('navbar-white');
                    element3.classList.add('navbar-c');
                }
            } else {
                if (element3) {
                    element3.classList.add('navbar-white');
                    element3.classList.remove('navbar-c');
                }
            }
        }
    },
    mounted () {
        window.addEventListener('scroll', this.updateScroll);
    },
    destroy () {
        window.removeEventListener('scroll', this.updateScroll);
    }
};
</script>

<style>

</style>
